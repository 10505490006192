<template>
  <nav class="bg-white shadow dark:bg-gray-800">
    <div class="container px-6 py-3 mx-auto md:flex md:justify-between md:items-center">
      <div class="flex items-center justify-between">
        <div>
          <router-link class="text-xl font-bold text-gray-800 dark:text-white md:text-2xl hover:text-gray-700
          dark:hover:text-gray-300" :to="{name: 'home'}">Pi'Air
          </router-link>
        </div>
        <!-- Mobile menu button -->
        <div class="flex md:hidden">
          <button type="button" class="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400
            focus:outline-none focus:text-gray-600 dark:focus:text-gray-400" aria-label="toggle menu">
            <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
              <path
                  fill-rule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              />
            </svg>
          </button>
        </div>
      </div>

      <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
      <div class="items-center md:flex">
        <div class="flex flex-col md:flex-row md:mx-6">
          <a class="my-1 text-gray-700 dark:text-gray-200 hover:text-green-600 dark:hover:text-green-400 md:mx-4
          md:my-0" href="#">A propos</a>
          <a v-if="false" class="relative text-gray-700 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-300"
             href="#">
            <font-awesome-icon icon="bell"/>
            <span class="absolute top-0 left-0 p-1 text-xs text-white bg-indigo-500 rounded-full"></span>
          </a>
        </div>

        <div class="flex justify-center md:block">
          <a class="relative text-gray-700 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-300" href="#">
            <font-awesome-icon icon="user"/>
          </a>
        </div>
      </div>
    </div>
  </nav>
  <div class="container w-full flex flex-auto mx-auto my-5">
    <router-view/>
  </div>
</template>

<style lang="less">
</style>
