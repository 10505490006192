import {createRouter, createWebHistory} from "vue-router";

function loadComponent(component) {
    return () => import(`@/views/${component}.vue`);
}

const routes = [
    {
        path: "/",
        name: "home",
        component: loadComponent("Home")
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
