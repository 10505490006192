import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.css";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faBell, faPlus, faTrash, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import mitt from "mitt";

library.add(faBell, faUser, faTrash, faPlus);

const emitter = mitt();

const app = createApp(App).use(store).use(router)
    .component("font-awesome-icon", FontAwesomeIcon);
app.config.globalProperties.emitter = emitter;
app.mount("#app");
